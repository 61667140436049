/* styles.css */

/* Reset some default styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

.top-container {
    display: flex;
    height: 100%;
}

.sidebar {
    width: 40%;
    background-color: #f0f0f0; /* Change this to the desired background color */
    /* Add any other styles you want for the sidebar */
    padding:10px;
    margin-top:0px;
    border-left: 1px solid rgb(171 171 171 / 67%);
    overflow-y: auto;
}

.app {
    flex: 1;
    background-color: #ffffff; /* Change this to the desired background color */
    /* Add any other styles you want for the main content */
}

button {
    /* Set background color cool blue */
    background-color: #4CAF50;
    border: 1px solid #4CAF50;
    border-radius: 4px;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;

    /* Add any other styles you want for buttons */
}
button:disabled {
    background-color: #cccccc;
    border: 1px solid #cccccc;
    cursor: not-allowed;
}
div.possibleNext {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    /* Add any other styles you want for the possible next steps */
}

button.downsized {
    padding: 5px 10px;
    margin-top: 10px;
}